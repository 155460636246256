export default function InlineVideo({ blok }) {
  const VideoURL =
    blok.platform === "vimeo"
      ? "https://player.vimeo.com/video/" +
        blok.video_id +
        "?autoplay=false&byline=false&portrait=false&title=false&transparent=false"
      : blok.platform === "youtube"
        ? "https://www.youtube.com/embed/" + blok.video_id + "?autoplay=0&mute=1"
        : ""

  return (
    <iframe
      src={VideoURL}
      className="my-4 w-full max-w-4xl"
      height="500"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embeded Video"
    />
  )
}
